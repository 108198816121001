import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar"; // Import Sidebar
import Navbar from "./Navbar"; // Import Navbar
import Popup from "./popup";
import axios from "axios";
import config from "config";

export default function Dashboard() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [error, setError] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupType, setPopupType] = useState(""); // 'success' or 'error'
  const [tableData, setTableData] = useState([]); // Store fetched data here
  const navigate = useNavigate();

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  // Dummy counts for each status
  const counts = {
    pending: 4,
    rescheduled: 7,
    approved: 10,
    rejected: 5,
  };

  if (error) {
    setPopupMessage(error);
    setPopupType("error");
    setShowPopup(true);
  }

  useEffect(() => {
    // Fetch data immediately on mount
    fetchTableData();

    // Set up an interval to call the API every 5 seconds
    const intervalId = setInterval(() => {
      fetchTableData();
    }, 5000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // empty dependency array to run only on mount

  const fetchTableData = async () => {
    const accessToken = sessionStorage.getItem("accessToken");

    try {
      const response = await axios.get(
        `${config.apiBaseUrl}/api/pending_requests`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setTableData(response.data.pending_requests); // Set fetched data to state
      console.log(response.data.pending_requests);
    } catch (error) {
      console.log(error);
      console.log("Failed to fetch data.");
    }
  };

  // Get agent ID from session storage
  const agentId = sessionStorage.getItem("AgentId");
  const { v4: uuidv4 } = require("uuid");

  // Function to handle the "Connect" button click
  const handleConnect = async (leadId) => {
    try {
      // Generate room name and meeting link
      const roomName = `room-${leadId}-${agentId}-${uuidv4()}`;
      const origin = window.location.origin; // Get dynamic origin
      const meetLink = `${origin}/${roomName}`;

      // Prepare the payload
      const payload = {
        lead_id: leadId,
        agent_id: agentId,
        meet_link: meetLink,
      };

      const accessToken = sessionStorage.getItem('accessToken');
      console.log(accessToken);

      // Send the POST request
      const response = await axios.post(
        `${config.apiBaseUrl}/api/start-video-call`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      // Handle success response
      if (response.status === 200) {
        // alert("Meeting Initited Successfully.");
        navigate(`/agentmeet/${roomName}`);
      } else {
        alert("Failed to initiate the meeting. Please try again.");
      }
    } catch (error) {
      console.error("Error initiating the meeting:", error);
      alert("An error occurred while connecting. Please try again.");
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      {/* Show popup if there is a message */}
      {showPopup && (
        <Popup
          message={popupMessage}
          type={popupType}
          onClose={() => setShowPopup(false)}
        />
      )}

      {/* Navbar Component */}
      <Navbar setError={setError} />

      <div className="flex flex-1 pt-16">
        {" "}
        {/* Adjusted padding to avoid navbar overlap */}
        {/* Sidebar Component */}
        <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        {/* Main Content */}
        <div className="flex-1 bg-gray-100 p-6 overflow-hidden">
          {" "}
          {/* Added overflow-hidden to prevent horizontal scroll */}
          <h1 className="text-2xl font-medium mb-7">VKYC - DASHBOARD</h1>
          {/* Tabs Section */}
          <div className="flex flex-wrap justify-center space-x-4 mb-6">
            {["Pending", "Rescheduled", "Approved", "Rejected"].map(
              (status, index) => (
                <div key={index} className="flex items-center space-x-2">
                  <button className="bg-white text-gray-800 px-2 py-2 mb-3 rounded-lg font-semibold shadow-sm shadow-orange-500">
                    {status}
                    <span className="bg-blue-500 text-white rounded-full text-xs px-2 py-1 ml-3">
                      {counts[status.toLowerCase()] || 0}
                    </span>
                  </button>
                </div>
              )
            )}
          </div>
          {/* Table Section */}
          <div className="overflow-x-auto bg-white shadow-md rounded-lg border border-gray-200">
            <table className="min-w-full table-auto text-left">
              <thead className="bg-gray-300">
                <tr>
                  <th className="px-4 py-2">Sr. No</th>
                  <th className="px-4 py-2">Lead Id</th>
                  <th className="px-4 py-2">Applied On</th>
                  <th className="px-4 py-2">Name</th>
                  <th className="px-4 py-2">Email</th>
                  <th className="px-4 py-2">Contact</th>
                  <th className="px-4 py-2">Customer Status</th>
                  <th className="px-4 py-2">Is Rescheduled</th>
                  <th className="px-4 py-2">Status</th>
                  <th className="px-4 py-2">Action</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {tableData.map((data, index) => (
                  <tr key={index} className="hover:bg-gray-100 text-center">
                    <td className="px-4 py-2">{index + 1}</td>
                    <td className="px-4 py-2">{data.lead_id}</td>
                    <td className="px-4 py-2">
                      {new Date(data.applied_on).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })}{" "}
                      {/* Outputs 'MM/DD/YYYY' */}
                    </td>
                    <td className="px-4 py-2">
                      {data.customer_kyc_details.first_name}
                    </td>
                    <td className="px-4 py-2">
                      {data.customer_kyc_details.email}
                    </td>
                    <td className="px-4 py-2">
                      {data.customer_kyc_details.contact}
                    </td>
                    <td className="px-4 py-2">
                      <span
                        className={
                          data.is_customer_online === 1
                            ? "text-green-500 font-medium"
                            : "text-red-500 font-medium"
                        }
                      >
                        {data.is_customer_online === 1 ? "Online" : "Offline"}
                      </span>
                    </td>
                    <td className="px-4 py-2">
                      <span
                        className={
                          data.is_rescheduled === 1
                            ? "text-green-500 font-medium"
                            : "text-red-500 font-medium"
                        }
                      >
                        {data.is_rescheduled === 1 ? "Yes" : "No"}
                      </span>
                    </td>
                    <td className="px-4 py-2">
                      <span
                        className={`px-3 py-1 rounded-full text-xs font-semibold ${data.vkyc_final_status === "InProgress"
                            ? "bg-gray-500"
                            : data.vkyc_final_status === "Approved"
                              ? "bg-green-500"
                              : data.vkyc_final_status === "Rejected"
                                ? "bg-red-500"
                                : "bg-gray-500"
                          } text-white`}
                      >
                        {data.vkyc_final_status}
                      </span>
                    </td>
                    <td className="px-4 py-2">
                      {data.vkyc_final_status === "Approved" ? (
                        <span
                          className="bg-green-500 text-white text-sm px-2 py-1 rounded-lg font-semibold whitespace-nowrap"
                          title="KYC Process Completed"
                        >
                          KYC Completed
                        </span>
                      ) : (
                        <button
                          className={`bg-blue-500 text-white text-sm px-2 py-1 rounded-sm transition duration-200 
      ${data.is_customer_online === 1
                              ? "hover:bg-blue-700"
                              : "bg-gray-400 cursor-not-allowed"
                            }`}
                          title="Start Video KYC Process"
                          disabled={data.is_customer_online !== 1} // disables if user is not online
                          onClick={() => handleConnect(data.lead_id)} // Pass the lead ID to the handler
                        >
                          Connect
                        </button>
                      )}
                    </td>

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
