import React, { useState } from "react";

const AccordionCard = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-4 border border-gray-300 rounded">
      {/* Accordion Header */}
      <div
        className="p-3 bg-gray-100 cursor-pointer flex justify-between items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="text-sm font-semibold">{title}</h3>
        <span>{isOpen ? "-" : "+"}</span>
      </div>

      {/* Accordion Content */}
      {isOpen && (
        <div className="p-3 bg-white">
          <p className="text-sm text-gray-700">{content}</p>
        </div>
      )}
    </div>
  );
};

export default AccordionCard;
