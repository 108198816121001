import React from 'react';
import { FaBars, FaHome, FaUser, FaQuestionCircle, FaChevronDown, FaAd } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const SidebarLink = ({ icon, label, isOpen, to }) => (
  <Link to={to} className="flex items-center space-x-2 hover:bg-gray-700 p-2 rounded-md cursor-pointer">
    <div className="text-xl">{icon}</div>
    {isOpen && <span className="text-md font-medium">{label}</span>}
  </Link>
);

export default function Sidebar({ isSidebarOpen, toggleSidebar }) {
  return (
    <div className={`${isSidebarOpen ? "w-64" : "w-16"} bg-gray-500 text-white p-4 duration-300 flex flex-col`}>
      <button
        onClick={toggleSidebar}
        className="text-xl ml-2 mb-4 focus:outline-none"
      >
        {isSidebarOpen ? <FaChevronDown /> : <FaBars />}
      </button>
      <div className="space-y-4">
        <SidebarLink icon={<FaHome />} label="Dashboard" isOpen={isSidebarOpen} />
        <SidebarLink icon={<FaUser />} label="Profile" isOpen={isSidebarOpen} />
        <SidebarLink icon={<FaQuestionCircle />} label="Help" isOpen={isSidebarOpen} />
        <SidebarLink icon={<FaAd />} label="Settings" isOpen={isSidebarOpen} />
        {/* <SidebarLink icon={<FaLaptopCode />} label="Lead Generation" isOpen={isSidebarOpen} to="/lead-generation" /> */}
      </div>
    </div>
  );
}
