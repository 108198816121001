import React from "react";

const IdentityVerification = () => {
  const handleStart = () => {
    window.location.href = "https://accounts.digilocker.gov.in";
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-100 p-4">
      <div className="w-full max-w-sm bg-white rounded-lg shadow-lg overflow-hidden">
        {/* Icon and Title Section */}
        <div className="flex flex-col items-center p-6">
          {/* Big Icon at the Top */}
          <div className="flex justify-center mb-11">
            <svg
              width="100"
              height="100"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="text-blue-600"
            >
              <path d="M12 2C8.13 2 5 5.13 5 9c0 3.31 2.69 6 6 6s6-2.69 6-6c0-3.87-3.13-7-7-7zm0 10c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7 11H5c-1.1 0-2-.9-2-2v-1.66c0-2.08 2.05-3.85 4.75-4.07 1.32.73 2.89 1.15 4.25 1.15s2.93-.42 4.25-1.15c2.7.22 4.75 1.99 4.75 4.07V21c0 1.1-.9 2-2 2zm-2-4H7v-.34c0-.85.69-1.53 1.53-1.53h7.94c.85 0 1.53.69 1.53 1.53V19z" />
            </svg>
          </div>
          <h1 className="text-xl font-bold text-black-800 mt-3">
            Verify your identity
          </h1>
          <p className="text-black-600 text-center text-sm font-semibold mt-2">
            Please keep the following documents ready before you proceed with
            your KYC process.
          </p>
          <hr className="w-full border-gray-300 my-4" />
        </div>

        {/* Checklist Items */}
        <div className="px-6 space-y-3 text-black-700 font-bold text-sm">
          <div className="flex items-center">
            <span className="mr-3">➡️</span>
            <p>Aadhaar Verification</p>
            <span className="ml-auto text-green-600">✔️</span>
          </div>
          <div className="flex items-center">
            <span className="mr-3">➡️</span>
            <p>PAN Verification</p>
            <span className="ml-auto text-green-600">✔️</span>
          </div>
          <div className="flex items-center">
            <span className="mr-3">➡️</span>
            <p>Video Call (Q&A)</p>
            <span className="ml-auto text-green-600">✔️</span>
          </div>
        </div>

        {/* Start Button */}
        <div className="p-6">
          <button
            className="w-full py-3 rounded-md bg-green-700 text-white font-semibold mt-40"
            onClick={handleStart}
          >
            Start
          </button>
        </div>
      </div>
    </div>
  );
};

export default IdentityVerification;

