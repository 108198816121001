// Popup.js
import React from "react";

export default function Popup({ message, onClose, type }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-6 w-80 shadow-lg text-center">
        <h3
          className={`text-lg font-semibold ${
            type === "success" ? "text-green-600" : "text-red-600"
          }`}
        >
          {type === "success" ? "Success" : "Error"}
        </h3>
        <p className="mt-2 text-gray-700">{message}</p>

        {/* Show the warning message only if the type is "success" */}
        {type === "success" && (
          <small>
            <p className="text-sm text-orange-500 mt-4">
              <strong>Important:</strong> Do not press the back button or close
              this page.
            </p>
          </small>
        )}

        <button
          onClick={onClose}
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
        >
          {type === "success" ? "Proceed" : "Close"}
        </button>
      </div>
    </div>
  );
}
