import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const UserConsent = () => {
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">

      {/* Header Section */}
      <header className="relative font-nunito text-xl text-center text-white bg-primary font-extrabold leading-7 py-4">
        {/* Back Button */}
        <button
          onClick={() => navigate(-1)}
          className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white font-bold text-2xl"
        >
          <img
            src="/icons/back.svg"
            alt="Back"
            className="w-4 h-4 xs:block sm:block md:block lg:hidden xl:hidden" // Adjust these as needed for size
          />
        </button>

        <div className="text-center">
          {/* Header content goes here */}
          Video KYC
        </div>

        {/* Close Button */}
        <button
          onClick={() => navigate(-1)}
          className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white font-bold text-2xl"
        >
          <img
            src="/icons/close.svg"
            alt="Close"
            className="w-3 h-3 xs:block sm:block md:block lg:hidden xl:hidden" // Adjust these as needed for size
          />
        </button>
      </header>

      {/* Main Content Section */}
      <main className="flex flex-col items-center justify-center p-4">
        <div className="max-w-xl w-full text-black mt-7">
          <p className="font-nunito text-center text-lg uppercase leading-6 font-bold text-secondary mb-5">
            User Consent
          </p>

          {/* Consent Text */}
          <div className="max-h-[50vh] overflow-y-auto scrollbar-hide pr-4">
            <ul className="list-disc list-inside space-y-2 pl-5">
              <li className="flex font-nunito text-base leading-6 font-normal text-black">
                <span className="mr-2">•</span>
                  <span>
                    Your Video interaction session with the Banker will be in the
                    recording mode.
                  </span>
              </li>
              <li className="flex font-nunito text-base leading-6 font-normal text-black">
                <span className="mr-2">•</span>
                  <span>
                    A live Photograph will be captured during the Video interaction
                    session with the Banker.
                  </span>
              </li>
              <li className="flex font-nunito text-base leading-6 font-normal text-black">
                <span className="mr-2">•</span>
                  <span>
                    Your Aadhaar details will be used for Aadhaar verification in the
                    V-CIP process.
                  </span>
              </li>
              <li className="flex font-nunito text-base leading-6 font-normal text-black">
                <span className="mr-2">•</span>
                  <span>
                    A photograph of Your PAN Card will be collected to perform PAN
                    verification in the V-CIP process.
                  </span>
              </li>
              <li className="flex font-nunito text-base leading-6 font-normal text-black">
                <span className="mr-2">•</span>
                  <span>
                    Your Live Location will be Captured in the V-CIP process.
                  </span>
              </li>
              <li className="flex text-black font-medium font-nunito text-base leading-6">
                <span className="mr-2">•</span>
                  <span>
                    You should ensure all the details are correct during the Video
                    interaction Session.
                  </span>
              </li>
              <li className="flex text-black font-medium font-nunito text-base leading-6">
                <span className="mr-2">•</span>
                  <span>
                    The Aadhaar XML packet or Aadhaar secure offline paperless e-KYC
                    will be used for the KYC process.
                  </span>
              </li>
            </ul>
          </div>
        </div>
      </main>

      {/* Footer Section */}
      <footer className="p-12 flex flex-col justify-center items-center">
        <label className="flex items-center font-nunito text-base leading-6 font-normal text-black mb-4 max-w-lg">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
            className="mr-2"
          />
          <span>
            I, Sourabh Mahadik with the V-CIP ID - 124790 agree with all the
            above-mentioned points and hereby, confirm my consent.
          </span>
        </label>
        <button
          onClick={() => navigate("/verification-type")}
          disabled={!isChecked}
          className={`w-full max-w-sm py-3 rounded-lg gap-7 text-white font-nunito font-medium text-base leading-6 ${
            isChecked ? "bg-primary hover:bg-primary-dark" : "bg-gray-400 cursor-not-allowed"
          } transition`}
        >
          Proceed
        </button>
      </footer>
      
    </div>
  );
};

export default UserConsent;
