import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

const MeetPage = ({ userType }) => {
  const { roomName } = useParams(); // Extract dynamic room name from the URL
  const jitsiContainerRef = useRef(null);
  const [meetingEnded, setMeetingEnded] = useState(false); // Track if the meeting has ended
  const navigate = useNavigate();

  useEffect(() => {
    const domain = "Vc.teamtime.live";

    const options = {
      roomName,
      width: "100%",
      height: "100%",
      parentNode: jitsiContainerRef.current,
      configOverwrite: {
        prejoinPageEnabled: false, // Skip pre-join page
        enableClosePage: false, // Disable Jitsi's end screen
        disableDeepLinking: true, // Prevent app download prompts on mobile
      },
      interfaceConfigOverwrite: {
        // TOOLBAR_BUTTONS: [], // Disable all buttons
      },
      
      userInfo: {
        displayName: userType === "agent" ? "Agent" : "User", // Set display name based on userType
      },
    };

    const api = new window.JitsiMeetExternalAPI(domain, options);
    api.addEventListener("videoConferenceJoined", () => {
      console.log("Meeting started");
    
      // Dynamically hide unwanted buttons using CSS
      const iframe = document.querySelector("iframe");
      
    });
    
    // Listen for the meeting end event
    api.addEventListener("videoConferenceLeft", () => {
      console.log("Meeting ended");
      setMeetingEnded(true); // Set meetingEnded to true when the meeting ends
    });

    return () => {
      console.log("Disposing Jitsi API instance");
      api.dispose(); // Cleanup the Jitsi API instance
    };
  }, [roomName, userType]);

  useEffect(() => {
    if (meetingEnded) {
      if (userType === "agent") {
        console.log("Navigating to the thank-you page for agent");
        navigate('/thank-you') // Navigate to Thank You
      } else {
        console.log("Redirecting non-agent to google.com");
        window.location.href = "https://www.google.com"; // Navigate to Google
      }
    }
  }, [meetingEnded, userType]);

  if (meetingEnded && userType === "agent") {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f4f4f4",
        }}
      >
        <h1>Thank you, Agent, for your service!</h1>
      </div>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div ref={jitsiContainerRef} style={{ width: "100%", height: "100%"  ,   position: "relative", // Ensure it occupies the full screen
}}></div>
    </div>
  );
};

export default MeetPage;

