import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "config";

const VideoKYCPage = () => {
  const navigate = useNavigate();
  const leadId = sessionStorage.getItem("Lead_Id");

  const [isModalOpen, setIsModalOpen] = useState(false); // Manage modal visibility
  const [slots, setSlots] = useState([]); // Store fetched slots
  const [selectedSlot, setSelectedSlot] = useState(""); // Track selected slot
  const [alertMessage, setAlertMessage] = useState(null); // Display success/failure messages
  const [isScheduleDisabled, setIsScheduleDisabled] = useState(false); // Disable Schedule button on success

  const handleInitiate = async () => {
    if (!leadId) {
      console.error("Lead_Id not found in sessionStorage.");
      return;
    }

    try {
      const token = generateToken();
      const resumeLink = `${window.location.origin}/proceed?token=${token}&id=${leadId}`;

      await axios.post(`${config.apiBaseUrl}/api/set-online`, {
        lead_id: leadId,
        token,
        link: resumeLink,
      });

      console.log("Initiate KYC API call successful.");
      navigate("/initiate-kyc");
    } catch (error) {
      console.error("Error in initiate KYC API call:", error);
    }
  };

  const handleSchedule = () => {
    setIsModalOpen(true); // Open modal to select slot
  };

  const fetchSlots = async () => {
    try {
      const response = await axios.get(`${config.apiBaseUrl}/api/slots`);
      setSlots(response.data.slots);
    } catch (error) {
      console.error("Error fetching slots:", error);
    }
  };

  const confirmSchedule = async () => {
    if (!selectedSlot) {
      alert("Please select a slot before confirming.");
      return;
    }

    try {
      const token = generateToken();
      const resumeLink = `${window.location.origin}/resume-kyc?token=${token}&id=${leadId}`;

      await axios.post(`${config.apiBaseUrl}/api/schedule_later`, {
        lead_id: leadId,
        link: resumeLink,
        slot: selectedSlot,
      });

      setAlertMessage("V-KYC scheduled successfully in your selected slot. Please check your email and join the meeting using the provided link at your scheduled time.");
      setIsModalOpen(false); // Close modal
      setIsScheduleDisabled(true); // Disable Schedule Later button
    } catch (error) {
      setAlertMessage("Failed to schedule V-KYC in the selected slot.");
      console.error("Error in schedule KYC API call:", error);
    }
  };

  const generateToken = () => {
    return Math.random().toString(36).substring(2, 10); // Simple random token
  };

  useEffect(() => {
    if (isModalOpen) {
      fetchSlots(); // Fetch slots when modal opens
    }
  }, [isModalOpen]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 px-4">
      <div className="w-full max-w-md bg-white shadow-lg rounded-lg p-6 border border-gray-300 transform transition-all ease-in-out duration-300">
        <div className="text-center">
          <h2 className="text-2xl font-semibold mb-6 text-gray-900">
            Proceed to Video KYC
          </h2>
          <div className="flex flex-col md:flex-row justify-center gap-4 mb-6">
            <button
              onClick={handleInitiate}
              className="w-full md:w-auto py-2 px-4 bg-indigo-600 text-white rounded-md shadow hover:bg-indigo-700 focus:outline-none transition-transform transform hover:scale-105"
            >
              Initiate Now
            </button>
            <button
              onClick={handleSchedule}
              disabled={isScheduleDisabled}
              className={`w-full md:w-auto py-2 px-4 rounded-md shadow focus:outline-none transition-transform transform hover:scale-105 ${isScheduleDisabled ? "bg-gray-400 cursor-not-allowed" : "bg-gray-600 hover:bg-gray-700 text-white"}`}
            >
              Schedule Later
            </button>
          </div>

          {alertMessage && (
            <div className="mt-4 text-sm text-green-700 bg-green-100 p-2 rounded-md">
              {alertMessage}
            </div>
          )}
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-6 shadow-lg w-96">
            <h2 className="text-xl font-bold mb-4">Select a Slot</h2>
            <div className="mb-4">
              <label htmlFor="slot" className="block text-sm font-medium text-gray-700">
                Available Slots
              </label>
              <select
                id="slot"
                name="slot"
                value={selectedSlot}
                onChange={(e) => setSelectedSlot(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="">Select a slot</option>
                {slots.map((slot) => (
                  <option key={slot.id} value={slot.id}>
                    {`${slot.from} - ${slot.to}`}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex justify-end">
              <button
                onClick={() => setIsModalOpen(false)}
                className="py-2 px-4 bg-gray-300 text-gray-700 rounded-md shadow hover:bg-gray-400 focus:outline-none mr-2"
              >
                Cancel
              </button>
              <button
                onClick={confirmSchedule}
                className="py-2 px-4 bg-indigo-600 text-white rounded-md shadow hover:bg-indigo-700 focus:outline-none"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoKYCPage;

