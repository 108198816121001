import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const VerificationType = () => {
  const navigate = useNavigate();
  const [verificationType, setVerificationType] = useState("");
  const [showError, setShowError] = useState(false);

  const handleProceed = () => {
    if (verificationType) {
      navigate("/identity-verification");
    } else {
      setShowError(true);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-100 p-4">
      <div className="w-full max-w-sm bg-white rounded-lg shadow-lg overflow-hidden">
        {/* Introductory Content */}
        <div className="p-6">
          <h1 className="text-xl font-bold text-black-700 mb-2">Select Verification Method</h1>
          <p className="text-gray-700 text-sm mb-4">
            Please choose your preferred method for identity verification. Ensure that you have the necessary documents ready for the selected method.
          </p>
          <hr className="border-gray-400 mb-4" />

          {/* Dropdown for Verification Type */}
          <label className="block text-black-700 font-semibold mb-2">Verification Type</label>
          <select 
            value={verificationType}
            onChange={(e) => {
              setVerificationType(e.target.value);
              setShowError(false); // Hide error when a selection is made
            }}
            className="w-full p-2 border rounded-md text-grey-700"
          >
            <option value="" disabled>Select a method</option>
            <option value="aadhaar">Aadhaar Card</option>
            <option value="digilocker">Digilocker</option>
          </select>
          
          {/* Error Message */}
          {showError && (
            <p className="text-red text-sm mt-2">
              Please select a verification method to proceed.
            </p>
          )}
        </div>

        {/* Start Button */}
        <div className="p-6">
          <button
            className="w-full py-3 rounded-md bg-green-700 text-white font-semibold mt-7"
            onClick={handleProceed}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerificationType;

