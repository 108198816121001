import React, { useEffect, useState } from "react";
import axios from "axios"; // Import axios
import config from "config";

const ReInitiateKYCPage = () => {
  const initialTime = 180; // 3 minutes in seconds
  const [timeLeft, setTimeLeft] = useState(initialTime);
  const [showModal, setShowModal] = useState(false);

  // Get URL parameters
  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");
  const leadId = params.get("id");

  sessionStorage.setItem("Lead_Id", leadId);

  console.log("Token:", token); // Output: nsnv8pcw
  console.log("ID:", leadId); // Output: 2

  useEffect(() => {
    if (timeLeft === 0) {
      offlineAPI(); // Call the API function
      setShowModal(true); // Show the modal when time reaches 0
      return; // Stop the interval when the time reaches 0
    }

    const timer = setInterval(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => clearInterval(timer); // Clear the interval on component unmount
  }, [timeLeft]);

  const offlineAPI = async () => {
    try {
      await axios.post(`${config.apiBaseUrl}/api/set-offline-reschedule`, {
        lead_id: leadId,
      });
      console.log("Offline.");
    } catch (error) {
      console.error("Error Offline KYC:", error);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  const progress = (timeLeft / initialTime) * 100;

  const closePopUP = () => {
    setShowModal(false); // Hide the modal
  }

  const handleTryAgain = async () => {
    setShowModal(false); // Hide the modal
    setTimeLeft(initialTime); // Reset the timer to start the process again

    if (!leadId) {
      console.error("Lead_Id not found.");
      return;
    }

    try {
      // Generate a unique token (you can use a library like UUID or your own method)
      const token = generateToken();

      // Generate the resumption link with the token as a query parameter
      const resumeLink = `${window.location.origin}/re-initiate-kyc?token=${token}&id=${leadId}`;
      // API call for initiating KYC
      await axios.post(`${config.apiBaseUrl}/api/set-online`, {
        lead_id: leadId,
        token: token, // send the token to the server
        link: resumeLink, // send the link to the server
      });
      console.log("Re Initiate KYC API call successful.");
    } catch (error) {
      console.error("Error in re-initiate KYC API call:", error);
    }
  };

  // Function to generate a unique token (you can use a library like uuid for better token generation)
  const generateToken = () => {
    return Math.random().toString(36).substring(2, 10); // simple random token for demo purposes
  };

  const [meetLinkCreated, setMeetLinkCreated] = useState(false);

  useEffect(() => {
    // Function to check if the meet link is created
    const checkMeetLink = async () => {
      const leadId = sessionStorage.getItem("Lead_Id");
      try {
        const response = await axios.post(
          `${config.apiBaseUrl}/api/check-meet-link`,
          {
            lead_id: leadId,
          }
        );
        if (response.data && response.data.meet_link) {
          setMeetLinkCreated(true);
          window.location.href = response.data.meet_link;
          console.log("Meet link has been created:", response.data.meet_link);
          // Stop further checks if the meet link is created
        } else {
          console.log("Meet link not created yet.");
        }
      } catch (error) {
        console.error("Error checking meet link:", error);
      }
    };

    // Set interval to call the function every 5 seconds
    const interval = setInterval(() => {
      checkMeetLink();
    }, 5000);

    // Cleanup interval on component unmount
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 px-4">
      <div className="w-full max-w-sm bg-white shadow-lg rounded-lg p-6 border border-gray-300 transform transition-all ease-in-out duration-300">
        <div className="flex flex-col items-center">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4 text-center">
            Please wait, our agent will be with you shortly
          </h2>
          <p className="text-gray-600 mb-6 text-center">
            Estimated wait time: <strong>3 minutes</strong>
          </p>

          <div className="relative w-44 h-44 flex items-center justify-center">
            <svg className="absolute w-full h-full transform rotate-180">
              <defs>
                <linearGradient id="gradient" x1="1" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#6366f1" />
                  <stop offset="100%" stopColor="#8b5cf6" />
                </linearGradient>
              </defs>
              <circle
                cx="50%"
                cy="50%"
                r="68"
                stroke="gray"
                strokeWidth="8"
                fill="none"
                className="text-gray-300"
              />
              <circle
                cx="50%"
                cy="50%"
                r="68"
                stroke="url(#gradient)"
                strokeWidth="8"
                fill="none"
                strokeDasharray="427"
                strokeDashoffset={(427 * (100 - progress)) / 100}
                strokeLinecap="round"
                className="transition-all duration-1000 ease-linear"
              />
            </svg>

            <div className="absolute text-2xl font-semibold text-gray-800">
              {formatTime(timeLeft)}
            </div>
          </div>

          <p className="text-gray-500 mt-6 text-center">
            Our agent will be available shortly.
          </p>
        </div>
      </div>

      {/* Modal for timeout */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 w-full max-w-md mx-4 text-center shadow-lg">
            <h3 className="text-lg font-semibold text-gray-900 mb-4">
              Sorry for the inconvenience
            </h3>
            <p className="text-gray-700 mb-6">
              Our agents are busy right now, Please Try Again.
              <br />
            </p>
            <button
              onClick={handleTryAgain}
              className="px-6 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
            >
              Try Again
            </button>
            <button
              onClick={closePopUP}
              className="px-6 py-2 ml-5 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition-colors"
            >
              I'll do Later
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReInitiateKYCPage;

