// LeadGeneration.js
import React, { useState, useEffect } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import axios from "axios";
import config from "config";
import Popup from "./popup";
import ConfirmPopup from "./ConfirmPopup";

export default function LeadGeneration() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [contact_no, setContact] = useState("");
  const [applied_on, setAppliedOn] = useState("");
  const [file, setFile] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupType, setPopupType] = useState(""); // 'success' or 'error'
  const [tableData, setTableData] = useState([]); // Store fetched data here
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [selectedLeadId, setSelectedLeadId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    // Fetch data when component mounts
    fetchTableData();
  }, []);

  const fetchTableData = async () => {
    const accessToken = sessionStorage.getItem("accessToken");

    try {
      const response = await axios.get(
        `${config.apiBaseUrl}/api/vkyc-request`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setTableData(response.data); // Set fetched data to state
    } catch (error) {
      setPopupMessage("Failed to fetch data.");
      setPopupType("error");
      setShowPopup(true);
    }
  };

  const handleDelete = async (id) => {
    const accessToken = sessionStorage.getItem("accessToken");

    try {
      await axios.delete(`${config.apiBaseUrl}/api/vkyc-request/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      setPopupMessage("Lead deleted successfully.");
      setPopupType("success");
      setShowPopup(true);
      fetchTableData(); // Refresh data after deletion
      setShowConfirmPopup(false);
    } catch (error) {
      setPopupMessage("Failed to delete lead.", error);
      setPopupType("error");
      setShowPopup(true);
    }
  };

  const handleEdit = (data) => {
    // Populate fields for editing
    setIsEditing(true);
    setEditId(data.id);
    setEmail(data.email);
    setName(data.name);
    setContact(data.contact_no);
    setAppliedOn(data.applied_on);
  };

  const updateLead = async (e) => {
    e.preventDefault();
    const accessToken = sessionStorage.getItem("accessToken");

    try {
      await axios.put(
        `${config.apiBaseUrl}/api/vkyc-request/${editId}`,
        { email, name, contact_no, applied_on },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      setPopupMessage("Lead updated successfully.");
      setPopupType("success");
      setShowPopup(true);
      setIsEditing(false);
      setEditId(null);
      setEmail("");
      setName("");
      setContact("");
      setAppliedOn("");
      fetchTableData(); // Refresh data after updating
    } catch (error) {
      setPopupMessage("Failed to update lead.");
      setPopupType("error");
      setShowPopup(true);
    }
  };

  const sendFile = async (e) => {
    e.preventDefault();

    const accessToken = sessionStorage.getItem("accessToken");
    const formData = new FormData();

    // Append only the file
    if (file) {
      formData.append("excel_file_leads", file);
    } else {
      setPopupMessage("Please select a file to upload.");
      setPopupType("error");
      setShowPopup(true);
      return;
    }

    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/api/vkyc-request-leads/bulk_upload`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setPopupMessage(response.data.message);
        setPopupType("success");
        setShowPopup(true);
        // Clear the file input after successful upload
        setFile(null);
      } else {
        setPopupMessage(response.data.message);
        setPopupType("error");
        setShowPopup(true);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      setPopupMessage(errorMessage);
      setPopupType("error");
      setShowPopup(true);
    }
  };

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const SentEmail = async (e) => {
    e.preventDefault();

    const accessToken = sessionStorage.getItem("accessToken");

    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/api/vkyc-request`,
        {
          email,
          name,
          contact_no,
          applied_on,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      // Check if login is successful and an access token is returned
      if (response.status === 201) {
        setPopupMessage(response.data.message);
        setPopupType("success");
        setShowPopup(true);
        // Clear form input fields after successful submission
        setEmail("");
        setName("");
        setContact("");
        setAppliedOn("");
      } else {
        // Use the API response error message if available
        setPopupMessage(response.data.message);
        setPopupType("error");
        setShowPopup(true);
      }
    } catch (error) {
      // Use the API response error message or fallback to a default message
      const errorMessage = error.response?.data?.message;
      setPopupMessage(errorMessage);
      setPopupType("error");
      setShowPopup(true);
    }
  };

  return (
    <div className="flex">
      <Navbar setError={(message) => alert(message)} />

      <div className="flex pt-16">
        {" "}
        {/* Adjusted padding to avoid navbar overlap */}
        <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      </div>

      <div className="flex-1 flex flex-col">
        <div className="flex-1 p-20 bg-gray-100">
          <h1 className="text-3xl font-semibold text-gray-800 mb-6">
            Lead Generation
          </h1>

          {/* Show popup if there is a message */}
          {showPopup && (
            <Popup
              message={popupMessage}
              type={popupType}
              onClose={() => setShowPopup(false)}
            />
          )}

          <div className="flex flex-col md:flex-row items-center justify-between gap-6 mb-6">
            <div className="flex-1 bg-white p-6 rounded-lg shadow-lg">
              <form>
                <h2 className="text-lg font-semibold text-gray-700 mb-4">
                  File Upload
                </h2>
                <input
                  type="file"
                  onChange={handleFileChange}
                  className="border border-gray-300 p-2 rounded-lg w-full mb-4"
                />
                <button
                  type="button"
                  onClick={sendFile}
                  className="bg-blue-500 text-white py-2 px-4 rounded-lg w-full hover:bg-blue-600"
                >
                  Upload File
                </button>
              </form>
            </div>

            <div className="flex flex-col items-center justify-center text-gray-600 font-bold text-lg">
              OR
            </div>

            <div className="flex-1 bg-white p-6 rounded-lg shadow-lg">
              <form onSubmit={isEditing ? updateLead : SentEmail}>
                <h2 className="text-lg font-semibold text-gray-700 mb-4">
                  {isEditing ? "Edit Lead" : "Add Lead"}
                </h2>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-600 mb-1">
                    Name
                  </label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter Name"
                    className="border border-gray-300 p-2 rounded-lg w-full"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-600 mb-1">
                    Email
                  </label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter email"
                    className="border border-gray-300 p-2 rounded-lg w-full"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-600 mb-1">
                    Contact No.
                  </label>
                  <input
                    type="text"
                    value={contact_no}
                    onChange={(e) => setContact(e.target.value)}
                    placeholder="Enter Contact No."
                    className="border border-gray-300 p-2 rounded-lg w-full"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-600 mb-1">
                    Applied On
                  </label>
                  <input
                    type="date"
                    value={applied_on}
                    onChange={(e) => setAppliedOn(e.target.value)}
                    className="border border-gray-300 p-2 rounded-lg w-full"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="bg-blue-500 text-white py-2 px-4 rounded-lg w-full hover:bg-blue-600"
                >
                  {isEditing ? "Update Lead" : "Add Lead"}
                </button>
              </form>
            </div>
          </div>

          <div className="overflow-x-auto mt-6 bg-white p-6 rounded-lg shadow-lg">
            <h4 className="text-1xl font-medium text-black-800 mb-4">
              Leads Table
            </h4>
            <table className="min-w-full bg-white text-left text-sm">
              <thead className="bg-gray-300">
                <tr>
                  <th className="px-4 py-2">Sr No.</th>
                  <th className="px-4 py-2">Lead ID</th>
                  <th className="px-4 py-2">Name</th>
                  <th className="px-4 py-2">Email</th>
                  <th className="px-4 py-2">Applied On</th>
                  <th className="px-4 py-2">Action</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {tableData.map((data, index) => (
                  <tr key={data.id} className="hover:bg-gray-100">
                    <td className="px-4 py-2">{index + 1}</td>
                    <td className="px-4 py-2">{data.id}</td>
                    <td className="px-4 py-2">{data.name}</td>
                    <td className="px-4 py-2">{data.email}</td>
                    <td className="px-4 py-2">
                      {new Date(data.applied_on).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })}{" "}
                      {/* Outputs 'MM/DD/YYYY' */}
                    </td>
                    <td className="px-4 py-2 flex items-center">
                      <FaEdit className="mr-4" onClick={() => handleEdit(data)} />
                      {/* Add margin-right for spacing */}
                      <FaTrash
                        className="cursor-pointer"
                        onClick={() => {
                          setSelectedLeadId(data.id);
                          setShowConfirmPopup(true); // Show popup on delete icon click
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Show ConfirmPopup only if showConfirmPopup is true */}
            {showConfirmPopup && (
              <ConfirmPopup
                message="Are you sure you want to delete this item?"
                onConfirm={() => handleDelete(selectedLeadId)}
                onCancel={() => setShowConfirmPopup(false)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
