import React, { useState } from "react";
import AccordionCard from "./AccordionCard";
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";

const Sidebar = ({ customerData }) => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [loading, setLoading] = useState(false); // Track loading state

  // Image click handler for opening lightbox
  const handleImageClick = (imageUrl) => {
    setLoading(true); // Set loading to true before opening lightbox
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      setLoading(false); // Set loading to false once image is loaded
      setCurrentImage(imageUrl);
      setIsLightboxOpen(true);
    };
  };

  const documents = [
    {
      type: "Uploaded Documents",
      data: [
        {
          label: "Aadhaar Photo",
          url: customerData?.aadhaar_photo,
        },
        {
          label: "Pan Photo",
          url: customerData?.pan_photo,
        },
        {
          label: "Customer Photo",
          url: customerData?.customer_photo,
        },
        {
          label: "Signature Photo",
          url: customerData?.signature_photo,
        },
      ],
    },
    {
      type: "Captured Documents",
      data: [
        {
          label: "Aadhaar Photo (Captured)",
          url: customerData?.captured_aadhaar_photo,
        },
        {
          label: "Pan Photo (Captured)",
          url: customerData?.captured_pan_photo,
        },
        {
          label: "Customer Photo (Captured)",
          url: customerData?.captured_customer_photo,
        },
        {
          label: "Signature Photo (Captured)",
          url: customerData?.captured_signature_photo,
        },
      ],
    },
  ];

  const accordionData = [
    {
      title: "Identity Details",
      content: (
        <div className="text-sm space-y-2">
          <div>
            <p>
              <strong>First Name:</strong> {customerData?.first_name || "N/A"}
            </p>
            <hr className="my-2 border-gray-300" />
          </div>
          <div>
            <p>
              <strong>Last Name:</strong> {customerData?.last_name || "N/A"}
            </p>
            <hr className="my-2 border-gray-300" />
          </div>
          <div>
            <p>
              <strong>Email:</strong> {customerData?.email || "N/A"}
            </p>
            <hr className="my-2 border-gray-300" />
          </div>
          <div>
            <p>
              <strong>Contact:</strong> {customerData?.contact || "N/A"}
            </p>
            <hr className="my-2 border-gray-300" />
          </div>
          <div>
            <p>
              <strong>Father/Spouse Name:</strong>{" "}
              {customerData?.father_spouse_name || "N/A"}
            </p>
            <hr className="my-2 border-gray-300" />
          </div>
          <div>
            <p>
              <strong>Gender:</strong> {customerData?.gender || "N/A"}
            </p>
            <hr className="my-2 border-gray-300" />
          </div>
          <div>
            <p>
              <strong>Marital Status:</strong>{" "}
              {customerData?.marital_status || "N/A"}
            </p>
            <hr className="my-2 border-gray-300" />
          </div>
          <div>
            <p>
              <strong>DOB:</strong> {customerData?.dob || "N/A"}
            </p>
            <hr className="my-2 border-gray-300" />
          </div>
          <div>
            <p>
              <strong>Nationality:</strong> {customerData?.nationality || "N/A"}
            </p>
          </div>
        </div>
      ),
    },
    {
      title: "Address Details",
      content: (
        <div className="grid grid-cols-2 gap-2 text-sm">
          <div className="pr-2 border-r border-gray-300">
            <h3 className="text-sm font-semibold mb-2">Current Address</h3>
            <p className="text-gray-700">
              {customerData?.current_address || "N/A"}
            </p>
          </div>
          <div className="pl-4">
            <h3 className="text-sm font-semibold mb-2">Permanent Address</h3>
            <p className="text-gray-700">
              {customerData?.permanent_address || "N/A"}
            </p>
          </div>
        </div>
      ),
    },
    ...documents.map((docCategory) => ({
      title: docCategory.type,
      content: (
        <div className="grid grid-cols-2 gap-4">
          {docCategory.data.map((doc, index) => (
            <div key={index} className="border rounded p-2">
              <img
                src={doc.url || "https://via.placeholder.com/150"}
                alt={doc.label}
                className="w-full h-auto object-cover rounded"
                onClick={() => handleImageClick(doc.url || "")}
              />
              <p className="text-center text-sm mt-2">{doc.label}</p>
            </div>
          ))}
        </div>
      ),
    })),
  ];

  return (
    <div className="w-3/12 bg-gray-100 p-4 overflow-hidden">
      {accordionData.map((item, index) => (
        <AccordionCard key={index} title={item.title} content={item.content} />
      ))}

      {/* Lightbox Modal */}
      {isLightboxOpen && (
        <Lightbox
          mainSrc={currentImage}
          onCloseRequest={() => setIsLightboxOpen(false)}
        />
      )}

      {/* Loading spinner or fallback */}
      {loading && <div>Loading...</div>}
    </div>
  );
};

export default Sidebar;
