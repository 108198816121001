// Navbar.js
import React, { useState, useEffect } from 'react';
import { FaUserCircle, FaBars } from 'react-icons/fa';
import axios from 'axios';
import config from "config";

export default function Navbar({ setError }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    // Retrieve email from local storage and set it in state
    const email = sessionStorage.getItem('userEmail');
    setUserEmail(email);
  }, []);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const toggleUserMenu = () => setIsUserMenuOpen(!isUserMenuOpen);

  const handleLogout = async () => {
    const email = sessionStorage.getItem('userEmail');
    const accessToken = sessionStorage.getItem('accessToken');

    // Check if email or access token is missing
    if (!email || !accessToken) {
      console.error('Email or access token missing from local storage');
      setError('Unable to log out. Please try again later.');
      return;
    }

    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/api/logout`,
        { email },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (response.status === 200) {
        // Clear user data from local storage
        sessionStorage.removeItem('userEmail');
        sessionStorage.removeItem('accessToken');

        // Redirect to login page
        window.location.replace('/'); // Use replace() to prevent back navigation
      } else {
        console.error('Unexpected response:', response);
        setError('Logout failed. Please try again.');
      }
    } catch (error) {
      console.error('Logout failed:', error.response || error.message);
      setError('Server error occurred. Please try again later.');
    }
  };

  return (
    <nav className="bg-blue-400 text-white fixed w-full flex items-center justify-between p-2 z-10">
      {/* Left side: Company Logo */}
      <div className="flex items-center space-x-2">
        <FaBars onClick={toggleSidebar} className="text-2xl cursor-pointer md:hidden" />
        <img src="/icons/Imperative.png" alt="Company Logo" className="w-24 h-12 mr-2" />
      </div>

      {/* Right side: Menu and User Profile */}
      <div className="hidden md:flex items-center space-x-6">
        <a href="#home" className="hover:underline">Home</a>
        <a href="#users" className="hover:underline">Users</a>
        <a href="#help" className="hover:underline">Help</a>

        <div className="relative">
          <FaUserCircle
            onClick={toggleUserMenu}
            className="text-3xl cursor-pointer"
          />
          {isUserMenuOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white text-gray-800 shadow-lg rounded-lg p-4 z-10">
              <h4 className="font-semibold">John Doe</h4>
              <p className="text-sm text-gray-500">{userEmail}</p>
              <button className="mt-2 w-full text-left text-blue-500 hover:underline"
              onClick={handleLogout}
              >
                Log Out
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Mobile User Menu */}
      <div className="md:hidden relative">
        <FaUserCircle
          onClick={toggleUserMenu}
          className="text-3xl cursor-pointer"
        />
        {isUserMenuOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-white text-gray-800 shadow-lg rounded-lg p-4 z-10">
            <h4 className="font-semibold">John Doe</h4>
            <p className="text-sm text-gray-500">johndoe@example.com</p>
            <button className="mt-2 w-full text-left text-blue-500 hover:underline">
              Log Out
            </button>
          </div>
        )}
      </div>
    </nav>
  );
}
