import React from "react";
import { useNavigate } from "react-router-dom";

const VideoKYC = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">

      {/* Header Section */}
      <header className="relative font-nunito text-xl text-center text-white bg-primary font-extrabold leading-7 py-4">
        {/* Back Button */}
        <button
          onClick={() => navigate(-1)}
          className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white font-bold text-2xl"
        >
          <img
            src="/icons/back.svg"
            alt="Back"
            className="w-4 h-4 xs:block sm:block md:block lg:hidden xl:hidden" // Adjust these as needed for size
          />
        </button>

        <div className="text-center">
          {/* Header content goes here */}
          Video KYC
        </div>

        {/* Close Button */}
        <button
          onClick={() => navigate(-1)}
          className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white font-bold text-2xl"
        >
          <img
            src="/icons/close.svg"
            alt="Close"
            className="w-3 h-3 xs:block sm:block md:block lg:hidden xl:hidden" // Adjust these as needed for size
          />
        </button>
      </header>

      {/* Main Content Section */}
      <main className="flex items-center justify-center p-4">
        <div className="text-black mt-7">
          {/* Main text content goes here */}

          <p className="font-nunito text-center text-lg uppercase leading-6 font-bold text-secondary mb-5">
            Instructions
          </p>

          <ul className="list-disc list-inside space-y-2 pl-5">
            <li className="flex font-nunito text-base leading-6 font-normal text-black">
              <span className="mr-2">•</span>
              <span>
                Do not refresh the page or access any other app, else, you will be
                logged out of the Video KYC process.
              </span>
            </li>
            <li className="flex font-nunito text-base leading-6 font-normal text-black">
              <span className="mr-2">•</span>
              <span>Please do not open the same link in multiple tabs.</span>
            </li>
            <li className="flex font-nunito text-base leading-6 font-normal text-black">
              <span className="mr-2">•</span>
              <span>Please ensure your internet connectivity is stable.</span>
            </li>
            <li className="flex font-nunito text-base leading-6 font-normal text-black">
              <span className="mr-2">•</span>
              <span>
                Enable camera, microphone, and location settings on your device.
              </span>
            </li>
            <li className="flex font-nunito text-base leading-6 font-normal text-black">
              <span className="mr-2">•</span>
              <span>Do not let your mobile screen go into sleep mode.</span>
            </li>
            <li className="flex text-red font-medium font-nunito text-base leading-6">
              <span className="mr-2">•</span>
              <span>Do not go back in any step of the KYC process.</span>
            </li>
          </ul>

        </div>
      </main>

      {/* Footer Button Section */}
      <footer className="p-16 flex justify-center">
        <button
          onClick={() => navigate("/user-consent")}
          className="w-full max-w-sm bg-primary text-white py-3 rounded-lg gap-7 transition-colors font-medium font-nunito text-base leading-6">
          {/* Button text goes here */}
          Get Started
        </button>
      </footer>

    </div>
  );
};

export default VideoKYC;
