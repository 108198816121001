// LoginPage.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "config";
import Popup from "./popup";

export default function LoginPage() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupType, setPopupType] = useState(""); // 'success' or 'error'

  const handleForgotPasswordClick = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Redirect if the user is already logged in
  useEffect(() => {
    const accessToken = sessionStorage.getItem("accessToken");
    if (accessToken) {
      navigate("/bank-user-dashboard", { replace: true });
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();

    // Validate inputs
    if (!email || !password) {
      setPopupMessage("Email ID or Password is Missing.");
      setPopupType("error");
      setShowPopup(true);
      return;
    }

    try {
      const response = await axios.post(`${config.apiBaseUrl}/api/login`, {
        email,
        password,
      });

      // Check if login is successful and an access token is returned
      if (response.data.access_token) {
        // Store the access token and email in sessionStorage
        sessionStorage.setItem("accessToken", response.data.access_token);
        sessionStorage.setItem("userEmail", email);
        sessionStorage.setItem("AgentId", response.data.agent_id);

        // Redirect to the dashboard
        navigate("/bank-user-dashboard");
      } else {
        // Use the API response error message if available
        setPopupMessage(response.data.message);
        setPopupType("error");
        setShowPopup(true);
      }
    } catch (error) {
      // Use the API response error message or fallback to a default message
      const errorMessage =
        error.response?.data?.message ||
        "Login failed. Please check your credentials and try again.";
      setPopupMessage(errorMessage);
      setPopupType("error");
      setShowPopup(true);
    }
  };

  return (
    <div className="min-h-screen flex relative">
      {/* Show popup if there is a message */}
      {showPopup && (
        <Popup
          message={popupMessage}
          type={popupType}
          onClose={() => setShowPopup(false)}
        />
      )}

      {/* Left Side: Wider Section with Logo and Video KYC Text */}
      <div className="flex flex-col items-center justify-center bg-blue-400 text-white w-2/5 md:w-1/3 min-h-screen p-8">
        <img
          src="/icons/Imperative.png" // Replace with your logo URL
          alt="Company Logo"
          className="w-80 h-40 mb-4"
        />
        <hr className="border-t border-white w-full mb-4" />
        <h2 className="text-3xl font-bold">Video KYC</h2>
      </div>

      {/* Right Side: Login Form Centered Card with Footer Text */}
      <div className="flex flex-col justify-center items-center w-3/5 md:w-2/3 p-6 bg-gray-100 relative">
        <div className="w-full max-w-md bg-white rounded-lg shadow-lg p-8">
          <h2 className="text-3xl font-semibold text-left text-gray-700 mb-6">
            Login
          </h2>
          <h4 className="text-1xl font-normal text-left text-black mb-6">
            Login to Get Started!
          </h4>
          <form className="space-y-6" onSubmit={handleLogin}>
            <div>
              <label className="block text-sm font-medium text-gray-600 mb-1">
                Email
              </label>
              <input
                type="text"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-800"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                autoComplete="off"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600 mb-1">
                Password
              </label>
              <input
                type="password"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-800"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                autoComplete="off"
              />
            </div>
            <button
              type="submit"
              className="w-full py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300"
            >
              Login
            </button>
          </form>
        </div>

        {/* Footer Text */}
        <div className="absolute bottom-6 right-8 text-gray-500 text-sm">
          &copy; Copyright 2024. All Rights Reserved.
        </div>
        <div className="absolute bottom-6 left-8">
          <button
            onClick={handleForgotPasswordClick}
            className="text-blue-500 hover:text-blue-700 text-sm underline"
          >
            Forgot Password?
          </button>
        </div>
      </div>

      {/* Forgot Password Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg w-96 p-6">
            <h2 className="text-2xl font-semibold text-center mb-4">
              Reset Password
            </h2>
            <p className="text-gray-600 mb-4 text-center">
              Enter your email to reset your password.
            </p>
            <form>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-600">
                  Email
                </label>
                <input
                  type="email"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-800"
                  placeholder="Enter your email"
                  required
                />
              </div>
              <div className="flex justify-center space-x-4">
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
                >
                  Submit
                </button>
                <button
                  onClick={closeModal}
                  className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-400 transition duration-300"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
